import React, { ReactElement } from 'react';
import Icon from '@components/Icon';
import { IDiscountBadgeProps } from './types';
import { IconTypesEnum } from '@components/Icon/types';
import clsx from 'clsx';

import styles from './styles/discountBadge.module.scss';

const DiscountBadge = ({ isSmall = false, isSmallOnMobile }: IDiscountBadgeProps): ReactElement => {
    const classes = clsx(styles.root, isSmall && styles.smallBadge, isSmallOnMobile && styles.smallBadgeOnMobile);

    return (
        <div className={classes}>
            <Icon name={'percent'} type={IconTypesEnum.ICON} size={'20'} />
        </div>
    );
};

DiscountBadge.displayName = 'DiscountBadge';

export default DiscountBadge;
