import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { IGridColumn } from '@components/Grid/Column/types';
import styles from './styles/gridCol.module.scss';

/*
 * Grid.Column 2.0
 *
 * Used only as a child of Grid.Row
 *
 */

const Column = ({ children, className, isFullWidth, size = {}, ...rest }: IGridColumn): ReactElement => {
    const { l, m, s } = size;

    // By default, column in each viewport takes full width
    const classes = clsx(
        styles.default,
        s && s < 12 && styles[`col--s--${s}`],
        m && m < 12 && styles[`col--m--${m}`],
        l && l < 12 && styles[`col--l--${l}`],
        isFullWidth && styles.fullWidth,
        className,
    );

    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
};

export default Column;
