import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { IGridRow } from '@components/Grid/Row/types';
import styles from '@components/Grid/Row/styles/gridRow.module.scss';

/*
 * Grid.Row 2.0
 *
 * Children Only Grid.Column element(s)
 */

const Row = ({ align, children, className, showPaddings, ...rest }: IGridRow): ReactElement => {
    const classes = clsx(
        styles.wrapper,
        align && styles[`align--${align}`],
        showPaddings && styles.showPaddings,
        className,
    );

    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
};

export default Row;
