import React, { ReactElement } from 'react';
import type { IPrice } from './types';
import { Typography } from '@components/Typography';
import { BodyStyles } from '@components/Typography/Body/types';
import styles from './styles/price.module.scss';

const formatPrice = (value: number, currency: string, location = 'en-GB', pattern) => {
    if (!value) {
        return null;
    }

    if (!pattern) {
        return new Intl.NumberFormat(location, {
            currency,
            style: 'currency',
        }).format(value);
    }

    const amount = new Intl.NumberFormat(location, {
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).formatToParts(value);

    const amountStart = pattern.findIndex(({ type }) => type === 'integer');
    const amountEnd = pattern.findLastIndex(({ type }) => type === 'fraction');

    pattern.splice(amountStart, amountEnd - amountStart + 1, ...amount);

    return pattern.map((item) => item.value).join('');
};

const Price = ({
    currency,
    finalPrice,
    location,
    pattern,
    regularPrice,
    regularPriceMax,
    showRange,
}: IPrice): ReactElement => {
    const hasDiscount = Boolean(finalPrice < regularPrice);
    const hasRange = Boolean(finalPrice < regularPriceMax);

    return (
        <div className={styles.price}>
            <Typography.Body style={BodyStyles.MINOR} styleDesktop={BodyStyles.BASIC}>
                {formatPrice(finalPrice, currency, location, pattern)}
            </Typography.Body>
            {hasRange && showRange && (
                <Typography.Body style={BodyStyles.MINOR} styleDesktop={BodyStyles.BASIC} className={styles.rangePrice}>
                    {formatPrice(regularPriceMax, currency, location, pattern)}
                </Typography.Body>
            )}
            {!showRange && hasDiscount && (
                <Typography.Body style={BodyStyles.MINOR} styleDesktop={BodyStyles.BASIC} className={styles.discount}>
                    {formatPrice(regularPriceMax, currency, location, pattern)}
                </Typography.Body>
            )}
        </div>
    );
};

export default Price;
