'use client';

import React, { ReactElement, useState } from 'react';
import { IAccordion, IAccordionItemProps } from './types';
import Icon from '@components/Icon';
import { IconTypesEnum } from '@components/Icon/types';
import Collapsible from 'react-collapsible';
import styles from './styles/accordion.module.scss';

const AccordionItem = ({
    content,
    isFilterAccordion,
    isOpen,
    onOpening,
    title,
}: IAccordionItemProps): ReactElement | null => {
    if (!content || !title) {
        return null;
    }

    return (
        <Collapsible
            className={styles.accordionContainer}
            transitionTime={300}
            openedClassName={isFilterAccordion ? styles.filterAccordionContainerOpen : styles.accordionContainerOpen}
            easing={'ease-in-out'}
            trigger={
                <span className={styles.summaryWrapper}>
                    <h3 className={styles.heading}>{title}</h3>
                    <Icon name={'plus-circle'} type={IconTypesEnum.ICON} size={'18'} className={styles.headingIcon} />
                </span>
            }
            open={isOpen}
            onOpening={onOpening}
        >
            <div className={isFilterAccordion ? styles.filterContent : styles.content}>{content}</div>
        </Collapsible>
    );
};

const Accordion = ({ isFilterAccordion, isOpenByDefault, items }: IAccordion): ReactElement[] | null => {
    const [activeAccordionIndex, setActiveAccordionIndex] = useState<number | null>(null);

    if (!items) {
        return null;
    }

    const handleAccordionClick = (index) => {
        setActiveAccordionIndex(null);
        setActiveAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return items.map((item, index) => {
        return (
            <AccordionItem
                content={item.content}
                title={item.title}
                key={index}
                isOpen={isOpenByDefault || activeAccordionIndex === index}
                onOpening={() => handleAccordionClick(index)}
                isFilterAccordion={isFilterAccordion}
            />
        );
    });
};

export default Accordion;
