import { TIcons } from '@components/Icon/types';

export enum NotificationInlineTypesEnum {
    DEFAULT = 'default',
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export interface INotificationInline {
    header?: string;
    text: string;
    type: NotificationInlineTypesEnum;
    customIcon?: TIcons;
}

export interface INotificationInlineStyle {
    icon: TIcons;
    style?: string;
}
