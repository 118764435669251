import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Visibility from '@components/Visibility';
import getSplittedChunk from '@utils/getSplittedChunk';
import getSlicedStringBySeparator from '@utils/getSlicedStringBySeparator';
import verifyLeadingChar from '@utils/verifyLeadingChar';
import { IEmbeddedHtml, IReplaceElement } from '@components/EmbeddedHtml/types';

const getHref = (event: Event) => {
    const current = event.currentTarget as Element;
    if (!event || !current || typeof current.getAttribute !== 'function') return null;

    return current.getAttribute('href');
};

const EmbeddedHtml = ({ className, html, style, visibility }: IEmbeddedHtml): ReactElement | null => {
    const elementRef = useRef<HTMLDivElement>(null);
    const historyApi = useHistory();

    const handlePush = useCallback(
        (to: string) => {
            const currentStoreCode = getSplittedChunk(window.location.pathname, 1);
            const splittedChunk = getSplittedChunk(to, 1);
            const localesExist = currentStoreCode.includes('_') && splittedChunk.includes('_');

            switch (true) {
                case localesExist && currentStoreCode === splittedChunk: {
                    const result = getSlicedStringBySeparator(to);
                    historyApi.push(`/${result}`);

                    break;
                }

                case (localesExist && currentStoreCode !== splittedChunk) ||
                    ['dam', 'content'].includes(splittedChunk): {
                    const href = verifyLeadingChar(to);

                    window.location.href = `${window.location.origin}${href}`;
                    break;
                }

                default: {
                    const href = verifyLeadingChar(to);

                    historyApi.push(href);
                    break;
                }
            }
        },
        [historyApi],
    );

    useEffect(() => {
        const elementDom = elementRef?.current;

        if (!elementDom) return;

        const currentLocation = window?.location;
        const onClickHandler = (event: Event) => {
            const href = getHref(event);
            if (typeof historyApi.push !== 'function' || !href) return;
            event.preventDefault();
            handlePush(href);
        };
        const onClickHandlerReplaced = (event: Event) => {
            const href = getHref(event);
            if (typeof historyApi.push !== 'function' || !href) return;
            event.preventDefault();
            historyApi.push(href.replace(currentLocation.origin, ''));
        };
        const createReplacementElement = (element: IReplaceElement) => {
            const { attributes, innerHTML, tagName } = element;
            const replacementElement = document.createElement(tagName);

            for (const { name, value } of attributes) {
                replacementElement.setAttribute(name, value);
            }

            if (!attributes.src) {
                replacementElement.innerHTML = innerHTML;
            }

            return replacementElement;
        };

        elementDom.querySelectorAll<HTMLElement>('script, a').forEach((currentElement) => {
            switch (currentElement.nodeName) {
                case 'SCRIPT': {
                    currentElement.replaceWith(createReplacementElement(currentElement));

                    return;
                }
                case 'A': {
                    const href = currentElement.getAttribute('href');

                    if (!href || typeof href !== 'string') {
                        return;
                    }

                    if (['mailto:', 'tel:', '#', 'file:'].some((item) => href.trim().indexOf(item) === 0)) {
                        return;
                    }

                    if (href.includes('http')) {
                        const linkData = new URL(href);
                        const currentLocation = window?.location;

                        if (!currentLocation || currentLocation.origin !== linkData.origin) return;

                        currentElement.addEventListener('click', onClickHandlerReplaced);
                    } else {
                        currentElement.addEventListener('click', onClickHandler);
                    }

                    return;
                }
                default: {
                    return;
                }
            }
        });

        return () => {
            elementDom.querySelectorAll('a').forEach((currentElement: HTMLAnchorElement) => {
                currentElement.removeEventListener('click', onClickHandler);
                currentElement.removeEventListener('click', onClickHandlerReplaced);
            });
        };
    }, [elementRef, html, historyApi, handlePush]);

    if (html && typeof html !== 'string') return null;

    return (
        <Visibility visibility={visibility}>
            <div className={className} ref={elementRef} style={style} dangerouslySetInnerHTML={{ __html: html }} />
        </Visibility>
    );
};

export default EmbeddedHtml;
