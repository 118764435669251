import React, { ReactElement, useMemo } from 'react';
import { Typography } from '@components/Typography';
import { BodyStyles } from '@components/Typography/Body/types';
import Icon from '@components/Icon';
import {
    INotificationInline,
    INotificationInlineStyle,
    NotificationInlineTypesEnum,
} from '@components/Notification/Inline/types';
import clsx from 'clsx';

import styles from './styles/inline.module.scss';

const Inline = ({ customIcon, header, text, type }: INotificationInline): ReactElement => {
    const { icon, style } = useMemo<INotificationInlineStyle>((): INotificationInlineStyle => {
        switch (type) {
            case NotificationInlineTypesEnum.SUCCESS:
                return {
                    icon: 'success',
                    style: styles.success,
                };
            case NotificationInlineTypesEnum.WARNING:
                return {
                    icon: 'warning',
                    style: styles.warning,
                };
            default:
                return {
                    icon: 'information',
                };
        }
    }, [type]);

    return (
        <div className={clsx(styles.wrapper, style)}>
            <Icon name={customIcon || icon} size={'18px'} />
            <div>
                {header && <Typography.Body style={BodyStyles.BOLD}>{header}</Typography.Body>}
                <Typography.Body>{text}</Typography.Body>
            </div>
        </div>
    );
};

export default Inline;
