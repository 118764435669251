import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { IconTypesEnum, ITooltip } from './types';
import styles from './styles/tooltip.module.scss';

const Tooltip = ({ children, className, header, position, text }: ITooltip) => {
    const [hasOpened, setHasOpened] = useState<boolean>(false);
    const [tooltipPosition, setTooltipPosition] = useState<IconTypesEnum | undefined>(position);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleClick = (): void => {
        setHasOpened((prevState) => !prevState);

        if (position) {
            setTooltipPosition(position);
        }

        if (!position) {
            const tooltipOffsetLeft = tooltipRef?.current?.getBoundingClientRect().left || 0;
            const tooltipOffsetTop = tooltipRef?.current?.getBoundingClientRect().top || 0;

            const xAxisTooltipPosition = window.innerWidth / 2 > tooltipOffsetLeft ? 'Left' : 'Right';
            const yAxisTooltipPosition = window.innerHeight / 2 > tooltipOffsetTop ? 'bottom' : 'top';

            setTooltipPosition((yAxisTooltipPosition + xAxisTooltipPosition) as IconTypesEnum);
        }
    };

    const handleOutsideClick = (e: MouseEvent): void => {
        if (tooltipRef.current && !tooltipRef.current.contains(e.target as Node)) {
            setHasOpened(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const classes = clsx(
        styles.tooltip,
        tooltipPosition && styles[tooltipPosition],
        hasOpened && styles.hasOpened,
        className,
    );

    return (
        <div className={styles.wrapper} ref={tooltipRef}>
            <div onClick={handleClick} className={styles.button} onKeyDown={handleClick} role="button" tabIndex={0}>
                {children}
            </div>
            <div className={classes}>
                {header && <div className={styles.header}>{header}</div>}
                {text && <div className={styles.text}>{text}</div>}
            </div>
        </div>
    );
};
export default Tooltip;
