import React, { ReactElement, useMemo } from 'react';
import { Typography } from '@components/Typography';
import { BodyStyles } from '@components/Typography/Body/types';
import Button from '@components/Button-2';
import Icon from '@components/Icon';
import type { INotificationToast } from '@components/Notification/Toast/types';
import { NotificationInlineTypesEnum, INotificationInlineStyle } from '../Inline/types';
import { ButtonVariantsEnum } from '@components/Button-2/types';
import clsx from 'clsx';

import styles from './styles/toast.module.scss';

const Toast = ({
    dismissible = false,
    handleClick,
    header,
    text,
    type = NotificationInlineTypesEnum.DEFAULT,
}: INotificationToast): ReactElement => {
    const { icon, style } = useMemo<INotificationInlineStyle>((): INotificationInlineStyle => {
        switch (type) {
            case NotificationInlineTypesEnum.SUCCESS:
                return {
                    icon: 'success',
                    style: styles.success,
                };
            case NotificationInlineTypesEnum.ERROR:
            case NotificationInlineTypesEnum.WARNING:
                return {
                    icon: 'warning',
                    style: styles.warning,
                };
            default:
                return {
                    icon: 'information',
                };
        }
    }, [type]);

    return (
        <div className={clsx(styles.wrapper, style)}>
            <Icon name={icon} size={18} />
            <div className={styles.text}>
                {header && <Typography.Body style={BodyStyles.BOLD}>{header}</Typography.Body>}
                <Typography.Body>{text}</Typography.Body>
            </div>
            {dismissible && (
                <Button variant={ButtonVariantsEnum.TRIGGER} onClick={handleClick}>
                    <Icon name={'close'} size={18} />
                </Button>
            )}
        </div>
    );
};

export default Toast;
